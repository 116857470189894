import Link from 'next/link';
import React from 'react';
import { qsStringify } from 'utils';

import { CustomLinkProps } from 'common-nextjs';

type Props = CustomLinkProps & {
  prefilledForm?: any;
};

const ValueGuideLink: React.FC<Props> = ({
  children,
  prefilledForm,
  ...props
}) => (
  <Link
    href={'/value-guide' + qsStringify(prefilledForm)}
    rel="nofollow noindex"
    prefetch={false}
    {...props}
  >
    {children}
  </Link>
);

export default ValueGuideLink;
