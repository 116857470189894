import Link from 'next/link';
import React from 'react';

import { CustomLinkProps } from 'common-nextjs';

const SellNowLink: React.FC<CustomLinkProps> = ({ children, ...props }) => (
  <Link href="/sell-now" prefetch={false} {...props}>
    {children}
  </Link>
);

export default SellNowLink;
