import { CustomLinkProps } from 'common-nextjs';
import Link from 'next/link';

type Props = CustomLinkProps & {
  type: 'terms' | 'privacy' | 'accessibility';
};

function TermsLink({ children, type, ...props }: Props) {
  return (
    <Link href={`/${type}`} prefetch={false} {...props} rel="nofollow noindex">
      {children}
    </Link>
  );
}

export default TermsLink;
