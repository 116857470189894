import Link from 'next/link';
import React from 'react';
import { hashParamsStringify, qsStringify } from 'utils';
import { AnchorProps } from '~/components/Links/A';
import { RailsFlattenedFacetQuery } from '~/typings/services/rails/facets';
import extractPathname from '~/utils/extractPathname';

export type ShopLinkProps = AnchorProps & {
  // Use the internal router rather than a hard link
  internalRouter?: boolean;
  legacyBehavior?: boolean;

  // Prefetch? In the old architecture there's a bug where it always 500s. Defaults to false.
  prefetch?: boolean;

  path?: string;
  query?: RailsFlattenedFacetQuery;
  hash?: RailsFlattenedFacetQuery;
  type?: 'shop' | 'sm' | 'nav';
};

export function generateShopLink({
  query,
  hash,
  path,
  type = 'shop',
}: Pick<ShopLinkProps, 'query' | 'path' | 'type' | 'hash'>) {
  if (!path) {
    return null;
  }

  if (path == '#') {
    return null;
  }

  const stringifiedQuery = qsStringify(query);
  if (!path) {
    return `/search${stringifiedQuery}`;
  }

  if (path.startsWith('/search')) {
    return path;
  }

  let fullPath: string;

  if (path.startsWith('http')) {
    const extracted = extractPathname(path);
    fullPath = extracted + stringifiedQuery;
  } else if (path.startsWith(`/${type}`)) {
    fullPath = path + stringifiedQuery;
  } else if (path.startsWith(type)) {
    fullPath = `/${path}${stringifiedQuery}`.replace('//', '/');
  } else {
    fullPath = `/${type}/${path}${stringifiedQuery}`.replace('//', '/');
  }

  if (hash) {
    const stringifiedHash = hashParamsStringify(hash);
    fullPath += stringifiedHash;
  }

  return fullPath;
}

const ShopLink: React.FC<ShopLinkProps> = ({
  children,
  internalRouter,
  legacyBehavior,
  path,
  prefetch,
  query,
  hash,
  rel,
  type = 'shop',
  ...props
}) => {
  const anchorProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
    rel,
  };
  const href = generateShopLink({ query, path, hash, type });

  if (!href) {
    return (
      <span {...anchorProps} {...props}>
        {children}
      </span>
    );
  }

  if (internalRouter) {
    return (
      <Link
        href="/shop/[...lander]"
        {...anchorProps}
        {...props}
        as={href}
        legacyBehavior={legacyBehavior}
        prefetch={prefetch || false}
      >
        {children}
      </Link>
    );
  }

  return (
    <a {...anchorProps} {...props} href={href}>
      {children}
    </a>
  );
};

export default ShopLink;
